let mapIsLoaded = false;
let staticMapIsLoaded = false;
function loadMap() {
  if (!mapIsLoaded && !window.matchMedia(`(max-width: ${544 / 16}em)`).matches) {
    if (document.body.classList.contains('is-contact') || document.scrollingElement.scrollTop > window.innerHeight) {
      mapIsLoaded = true;
      const linkElement = document.createElement('link');
      linkElement.setAttribute('rel', 'stylesheet');
      linkElement.setAttribute('href', 'https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.css');
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('defer', true);
      scriptElement.setAttribute('src', '/assets/js/map.6436da23.js');
      const headElement = document.querySelector('html head');
      headElement.appendChild(linkElement);
      headElement.appendChild(scriptElement);
      window.removeEventListener('scroll', loadMap);
    }
  } else if (!staticMapIsLoaded) {
    staticMapIsLoaded = true;
    [...document.querySelectorAll('.contact-map figure img')].forEach((element) => {
      element.setAttribute('src', element.dataset.src);
    });
  }
}
window.addEventListener('resize', loadMap);
window.addEventListener('scroll', loadMap, { passive: true });
loadMap();
